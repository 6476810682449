export const testIds = {
    events_info: "events-info",
    booking_section: "booking-section",
    tel_image: "tel-image",
    contact_name: "contact-name",
    contact_email: "contact-email",
    contact_phone: "contact-phone",
    contact_message: "contact-message",
    contact_submit: "contact-submit",
    contact_info_item: "contact-info-item",
    terms: "terms",
    errors_text: "errors-text",
    rooms_counter: "rooms-counter",
    towns_counter: "towns-counter",
    escapers_counter: "escapers-counter",
    toggle_div: "toggle-div",
    modal: "modal",
    google_map_section: "google-map-section",
    mock_swiper: "mock-swiper",
    desktop_map: "desktop-map",
    mobile_map: "mobile-map",
    see_all: "see-all",
    opening_hours: "opening-hours",
    find_us: "find-us",
    popup_container: "pop-up-container",
    popup_open: "popup-open",
    contact_button: "contact-button",
    close_button: "close-button",
    contact_form: "contact-form",
};
