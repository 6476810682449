import "App.scss";
import { useEffect, useState, lazy, Suspense } from "react";
import { useShouldShow } from "helpers/useShouldShow";
import { loadResources } from "services/translations/i18n";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "common/css/shared.css";
import "common/fonts/Raleway.css";
import "common/fonts/Berkahi.css";
import "common/css/grayscale.min.css";
import "common/css/grayscale.css";
import Booking from "components/Booking/Booking";
import CareersPage from "pages/Careers/Careers";
import OutdoorActivity from "pages/Room/OutdoorActivity";
import NavigateToHomePage from "components/Navigate/Navigate";

// Define lazy-loaded components
const Home = lazy(() => import("pages/Home/Home"));
const Room = lazy(() => import("pages/Room/Room"));
const EventsPage = lazy(() => import("pages/Events/Events"));
const GiftCardPage = lazy(() => import("pages/GiftCardPage/GiftCardPage"));
const Contact = lazy(() => import("pages/Contacts/Contact"));
const BoardGames = lazy(() => import("pages/BoardGames/BoardGames"));
const FaqPage = lazy(() => import("pages/FAQS/FAQS"));
const TeamBuildingPage = lazy(() => import("pages/TeamBuilding/TeamBuilding"));
const BirthdayPartyPage = lazy(() =>
    import("pages/BirthdayParty/BirthdayPartyPage")
);
const BirthdayPacksPage = lazy(() =>
    import("pages/BirthdayPacks/BirthdayPacksPage")
);
const VouchersPage = lazy(() => import("pages/Vouchers/VouchersPage"));
const TermsAndConditionsPage = lazy(() =>
    import("pages/TermsAndConditions/TermsAndConditions")
);
const PrivacyPage = lazy(() => import("pages/PrivacyPage/Privacy"));
const RomePrivacyPage = lazy(() => import("pages/PrivacyPage/RomePrivacy"));
const VRGamesPage = lazy(() => import("pages/VRgames/VRgames"));
const LeaderboardPage = lazy(() => import("pages/Leaderboard/Leaderboard"));
const BachelorPartyPage = lazy(() =>
    import("pages/BachelorParty/BachelorPartyPage")
);
const AccessibilityPage = lazy(() =>
    import("pages/Accessibility/Accessibility")
);
const DiscountsPage = lazy(() => import("pages/Discounts/Discounts"));
const CustomEvents = lazy(() => import("components/CustomEvents/CustomEvents"));
const Footer = lazy(() => import("components/Footer/Footer"));
const Social = lazy(() => import("components/Homepage/Social/Social"));
const Navigation = lazy(() => import("components/Navigation/Navigation"));
const MediaPage = lazy(() => import("pages/Media/Media"));

const locale = navigator.language;

function App() {
    const [loaded, setLoaded] = useState(false);
    const { shouldShow } = useShouldShow();

    useEffect(() => {
        if (!loaded) {
            loadResources(localStorage.getItem("language")).then(() =>
                setLoaded(true)
            );
        }
    }, [localStorage, loaded]);

    return (
        <div className='App'>
            <BrowserRouter>
                <Suspense>
                    <Navigation />
                    <Routes>
                        <Route
                            path='/'
                            element={
                                <Home
                                    lang={locale}
                                    shouldShow={shouldShow}
                                />
                            }
                        />
                        <Route
                            path='/rooms/:room'
                            element={<Room />}
                        />
                        <Route
                            path='/rooms/outdoor/:room'
                            element={<OutdoorActivity />}
                        />
                        <Route
                            path='/events'
                            element={<EventsPage />}
                        />
                        <Route
                            path='/gift-card'
                            element={<GiftCardPage />}
                        />
                        <Route
                            path='/contact'
                            element={<Contact />}
                        />
                        <Route
                            path='/board-games'
                            element={<BoardGames />}
                        />
                        <Route
                            path='/faqs'
                            element={<FaqPage />}
                        />
                        <Route
                            path='/team-building'
                            element={<TeamBuildingPage />}
                        />
                        <Route
                            path='/birthday-party'
                            element={<BirthdayPartyPage />}
                        />
                        <Route
                            path='/:type/:storeName'
                            element={<Booking />}
                        />
                        <Route
                            path='/vouchers'
                            element={<VouchersPage />}
                        />
                        <Route
                            path='/terms-and-conditions'
                            element={<TermsAndConditionsPage />}
                        />
                        <Route
                            path='/privacy_and_cookies'
                            element={<PrivacyPage />}
                        />
                        <Route
                            path='/privacy'
                            element={<RomePrivacyPage />}
                        />
                        <Route
                            path='/leaderboard'
                            element={<LeaderboardPage />}
                        />
                        <Route
                            path='/accessibility'
                            element={<AccessibilityPage />}
                        />
                        <Route
                            path='/discounts'
                            element={<DiscountsPage />}
                        />
                        <Route
                            path='/vr-games'
                            element={<VRGamesPage />}
                        />
                        <Route
                            path='/bachelor-party'
                            element={<BachelorPartyPage />}
                        />
                        <Route
                            path='/birthday-packs'
                            element={<BirthdayPacksPage />}
                        />
                        <Route
                            path='/media'
                            element={<MediaPage />}
                        />
                        <Route
                            path='/careers'
                            element={<CareersPage />}
                        />
                        <Route
                            path='/enterprises'
                            element={<CustomEvents type={"enterprises"} />}
                        />
                        <Route
                            path='*'
                            element={<NavigateToHomePage />}
                        />
                    </Routes>
                    {(window.location.pathname !== "/" || shouldShow) && (
                        <>
                            <Social />
                            <Footer />
                        </>
                    )}
                </Suspense>
            </BrowserRouter>
        </div>
    );
}

export default App;
