import React from "react";
import ReactDOM from "react-dom/client";
import "../src/services/translations/i18n";
import "./index.scss";
import config from "config/main-config.json";
import { setGoogleAnalytics } from "helpers/index.helpers";
import App from "./App";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
const website_title = `${config.stores[0].location.city} - GAME OVER Escape Rooms - ${config.stores[0].location.country}`;
document.getElementById("website_title").innerText = website_title;
const googleTagSrc = config.google_tags?.google_analytics?.src;
const googleTagScript = config.google_tags?.google_analytics?.script;
document.getElementById("google_analytics_src").src = googleTagSrc;
document.getElementById("canonical_link").setAttribute("href", window.location);
document.getElementById("google_analytics_script").innerText = googleTagScript;

if (config.google_tags.google_tag_manager) {
    document.getElementById("google_tag_manager_script").innerText =
        config.google_tags.google_tag_manager.script;
    document.getElementById("google_tag_manager_noscript").innerText =
        config.google_tags.google_tag_manager.no_script_iframe;
}

if (config.heusden_script) {
    document.getElementById("heusden_script").innerText = config.heusden_script;
}

if (config.atlanta_popup_script) {
    document.getElementById("signupScript").src = config.atlanta_popup_script;
}

if (config.showScrollbarClass) {
    document.body.classList.add("show-scrollbar");
}

if (config.meta_pixel_code) {
    document.getElementById("meta_pixel_code").innerText =
        config.meta_pixel_code.script;
    document.getElementById("meta_pixel_code_noscript").innerText =
        config.meta_pixel_code.noscript;
}

if (Array.isArray(config.google_tags?.google_analytics)) {
    setGoogleAnalytics(config);
}

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
